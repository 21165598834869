import React from 'react';
import { Card, CardHeader, CardContent, Typography, Divider, Box } from '@mui/material';
import { DatabridgeAdminContext } from '../contexts/DatabridgeAdminContext'; // Adjust this import path as needed

const AdapterPartnerDetails = () => {
  const { selectedIntegration } = React.useContext(DatabridgeAdminContext);
  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardHeader title="Adapter & Partner Details" />
      <Divider />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Adapter:
        </Typography>
        {selectedIntegration && selectedIntegration.adapter ? (
          <Box mb={2}>
            <Typography>Name: {selectedIntegration.adapter.name}</Typography>
            <Typography>Description: {selectedIntegration.adapter.description || 'N/A'}</Typography>
          </Box>
        ) : (
          <Typography>No adapter details available.</Typography>
        )}
        <Typography variant="h6" gutterBottom>
          Partner:
        </Typography>
        {selectedIntegration && selectedIntegration.partner ? (
          <Box mb={2}>
            <Typography>Name: {selectedIntegration.partner.name}</Typography>
            <Typography>Description: {selectedIntegration.partner.description || 'N/A'}</Typography>
          </Box>
        ) : (
          <Typography>No partner details available.</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AdapterPartnerDetails;
