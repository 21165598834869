import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { ExternalApiPartner } from '../contexts/ExternalApiPartnersContext';

const partnerColumns: IDataTableColumn<ExternalApiPartner>[] = [
  {
    name: 'ID',
    selector: (row) => row.external_api_partner_id.toString(),
    sortable: true,
  },
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Enabled',
    selector: (row) => (row.enabled ? 'Yes' : 'No'),
    sortable: true,
  },
  {
    name: 'Last Modified By',
    selector: (row) => row.last_modified_by,
    sortable: true,
  },
  {
    name: 'Updated At',
    selector: (row) => row.updated_at,
    sortable: true,
  },
];

export default partnerColumns;
