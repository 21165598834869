/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import columns from '../constants/columns';
import { SeedsAutoTreatContext } from '../contexts/SeedsAutoTreatContext';
import { SeedAutoTreat } from '../model';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import SeedAutoTreatFilterBar from '../components/SeedsAutoTreatFilterBar';
import { AuthContext } from '../../../modules/auth/contexts/AuthContext';
import SelectInput from '../../../modules/shared/form/SelectInput';

const SeedsAutoTreatList: React.FunctionComponent = () => {
  const history = useHistory();

  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const [tableFilters, setTableFilters] = React.useState({
    variety: '',
    crop_type: '',
  });

  const { seeds, setCreatingPage, setDetailPage, setActiveSeedAutoTreat, activeMachine, setActiveMachine } =
    React.useContext(SeedsAutoTreatContext);

  const [activePartner, setActivePartner] = React.useState<string>(null);
  const [partnerModalOpen, setPartnerModalOpen] = React.useState(false);
  const [userHasManageIntegrationRecords, setUserHasManageIntegrationRecords] = React.useState(false);
  const { autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);
  const { currentUserHasUserPermission } = React.useContext(AuthContext);

  React.useEffect(() => {
    const manageIntegrationRecords = sessionStorage.getItem('manageIntegrationRecords');

    if (manageIntegrationRecords?.toLowerCase() === 'true') {
      setUserHasManageIntegrationRecords(true);
    } else {
      setUserHasManageIntegrationRecords(false);
    }
  }, []);

  const buttonsDisabled = () => {
    return (
      activeMachine?.value === 'Select A Machine' ||
      activeMachine?.value === undefined ||
      !activeMachine?.subscription_active ||
      activeMachine?.online_status === 'offline'
    );
  };

  const handlePartnerModalClose = () => {
    setPartnerModalOpen(false);
    setActivePartner(null);
  };

  const handlePartnerModalCloseAndRedirect = () => {
    setPartnerModalOpen(false);
    history.push(`/autotreat/machines/${activeMachine?.value}/partners/seeds_import?api_partner=${activePartner}`);
  };

  const filteredSeeds = () => {
    if (tableFilters.variety || tableFilters.crop_type) {
      const varietyFiltered = seeds.filter((seed) =>
        seed.variety?.toLowerCase().includes(tableFilters.variety?.toLowerCase()),
      );

      const cropTypeFiltered = varietyFiltered.filter((seed) =>
        seed.crop_name?.toLowerCase().includes(tableFilters.crop_type?.toLowerCase()),
      );

      return cropTypeFiltered;
    }

    return seeds;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Seed Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <SeedAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        {partnerModalOpen && (
          <Dialog
            open={partnerModalOpen}
            onClose={handlePartnerModalClose}
            maxWidth="md"
            PaperProps={{ style: { width: '40vw' } }} // Custom width
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <h2>
                  <strong>Please Select An API Partner</strong>
                </h2>
                <br />
                <Grid item xs={12} md={12}>
                  <SelectInput
                    choices={(activeMachine?.api_partners as any).map((partner) => ({
                      label: partner.api_partner_name,
                      value: partner.global_api_partner_id,
                    }))}
                    value={activePartner}
                    onChange={(value) => setActivePartner(value)}
                    label="API Partner"
                  />
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePartnerModalCloseAndRedirect} color="primary" autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Card>
          <CardContent>
            <Grid justifyContent="space-between" container spacing={0}>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                  Seed List
                </Typography>
              </Grid>
              <Grid item>
                {currentUserHasUserPermission('company_super_admin') && (
                  <>
                    {activeMachine?.manage_integration_records &&
                      Object.keys(activeMachine.api_partners || {}).length > 0 &&
                      userHasManageIntegrationRecords && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 7 }}
                            disabled={buttonsDisabled()}
                            onClick={() => {
                              setPartnerModalOpen(true);
                            }}
                          >
                            Bulk Import For API Partner
                          </Button>
                        </>
                      )}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: 7 }}
                      disabled={buttonsDisabled()}
                      onClick={() => {
                        history.push(`/autotreat/machines/${activeMachine?.value}/seeds_import`);
                      }}
                    >
                      Bulk Import
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={buttonsDisabled()}
                  onClick={() => {
                    setCreatingPage(true);
                    setFitlerBarActive(false);
                  }}
                >
                  New Seed
                </Button>
              </Grid>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filteredSeeds()}
                defaultSortField="SeedID"
                defaultSortAsc={false}
                striped
                onRowClicked={(row: SeedAutoTreat) => {
                  setActiveSeedAutoTreat(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default SeedsAutoTreatList;
