import React from 'react';
import { Box, Grid, Typography, Button, Card, CardContent } from '@mui/material';
import DeleteModal from '../../shared/modal/DeleteModal';
import { SnackBarContext } from '../../snackBar/contexts/SnackBarContext';
import { ExternalApiPartner } from '../contexts/ExternalApiPartnersContext';
import PartnerForm from './PartnerForm';
import { AuthContext } from '../../auth/contexts/AuthContext';
import { ExternalApiPartnersContext } from '../contexts/ExternalApiPartnersContext';

interface PartnerDetailsProps {
  partner?: ExternalApiPartner;
}

const PartnerDetails: React.FC<PartnerDetailsProps> = ({ partner }) => {
  const { showSuccessSnackBar } = React.useContext(SnackBarContext);
  const { partnerSubmitting } = React.useContext(ExternalApiPartnersContext);
  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleDelete = () => {
    // if (partner && deletePartner) {
    //   deletePartner(partner.external_api_partner_id.toString()).then(() => {
    showSuccessSnackBar('Partner deleted.');
    //   });
    // }
    setModalOpen(false);
  };

  return (
    <Box>
      <DeleteModal
        isOpen={modalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleDelete}
        confirmLabel="Delete"
      >
        <div style={{ margin: '10px 20px' }}>
          <Typography variant="h2" component="h2">
            Are you sure you want to delete this partner?
          </Typography>
        </div>
      </DeleteModal>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <br />
          <Typography variant="h2" component="h2" style={{ marginBottom: 8 }}>
            Partner Information
          </Typography>
          <PartnerForm partner={partner} />
          <br />
          <hr />
          {currentUserHasAdminPermission('ksi_admin') && (
            <>
              <br />
              <Typography variant="h2" component="h2" style={{ marginBottom: 8 }}>
                Additional Actions
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={() => setModalOpen(true)}
                disabled={partnerSubmitting}
                style={{ background: 'rgb(244, 79, 100)' }}
              >
                {partnerSubmitting ? 'Deleting...' : 'Delete Partner'}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PartnerDetails;
