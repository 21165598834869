import React from 'react';
import { Card, CardHeader, CardContent, Typography, Divider, Box, Grid, TextField } from '@mui/material';

const GlobalConfiguration = ({
  configName,
  setConfigName,
  awsSecretId,
  setAwsSecretId,
  refreshToken,
  setRefreshToken,
  authorizationCode,
  setAuthorizationCode,
}) => (
  <Card variant="outlined" sx={{ mb: 4 }}>
    <CardHeader title="Global Configuration" />
    <Divider />
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField label="Config Name" fullWidth value={configName} onChange={(e) => setConfigName(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="AWS Secret ID"
            fullWidth
            value={awsSecretId}
            onChange={(e) => setAwsSecretId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Refresh Token"
            fullWidth
            value={refreshToken}
            onChange={(e) => setRefreshToken(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Authorization Code"
            fullWidth
            value={authorizationCode}
            onChange={(e) => setAuthorizationCode(e.target.value)}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default GlobalConfiguration;
