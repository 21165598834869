import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import partnerColumns from '../constants/partnerColumns';
import { ExternalApiPartnersContext } from '../contexts/ExternalApiPartnersContext';
import PartnerFilterBar from '../components/PartnerFilterBar';

const ExternalApiPartnersListPage: React.FC = () => {
  const history = useHistory();
  const { fetchPartners, partners, partnersLoading } = React.useContext(ExternalApiPartnersContext);
  const [filterValues, setFilterValues] = React.useState({ search: '' });

  React.useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1">External API Partners</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => history.push('/admin/api_management/new')}>
            Create Partner
          </Button>
        </Grid>
      </Grid>
      <br />
      <PartnerFilterBar
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        onSearch={() => {
          if (fetchPartners) fetchPartners();
        }}
      />
      <br />
      <Card>
        <CardContent>
          <DataTable
            noHeader
            columns={partnerColumns}
            data={partners || []}
            defaultSortAsc={false}
            striped
            onRowClicked={(row) => history.push(`/admin/api_management/${row.external_api_partner_id}`)}
            highlightOnHover
            pointerOnHover
            progressPending={partnersLoading}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10]}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default ExternalApiPartnersListPage;
