import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import IntegrationForm from './IntegrationForm';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { CustomerIntegrationsAutoTreatContext } from '../contexts/CustomerIntegrationsAutoTreatContext';
import { integrationColumns } from '../constants/columns';
import { Machine } from '../../../modules/machinesAdminManagement/model';

interface IntegrationsListProps {
  activeMachine: Machine;
  customer: any;
}

const IntegrationsList: React.FC<IntegrationsListProps> = (props) => {
  const { activeMachine, customer } = props;

  const { customerIntegrations, customerIntegrationsLoading, customerIntegrationsSubmitting } = React.useContext(
    CustomerIntegrationsAutoTreatContext,
  );
  const [showIntegrationEditForm, setShowIntegrationForm] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] = React.useState<Record<string, string>>({});

  const handleAddIntegration = () => {
    setShowIntegrationForm(true);
    setSelectedIntegration({ customer_id: customer?.customer_id });
  };

  const handleEditIntegration = (integration) => {
    if (sessionStorage.getItem('manageIntegrationRecords') === 'true' && activeMachine.manage_integration_records) {
      setShowIntegrationForm(true);
      setSelectedIntegration(integration);
    }
  };

  return showIntegrationEditForm ? (
    <>
      <IntegrationForm
        activeMachine={activeMachine}
        CustomerId={customer?.customer_id}
        customerIntegration={selectedIntegration}
        setShowIntegrationForm={setShowIntegrationForm}
        machineSerial={activeMachine?.value}
        partnerChoices={(activeMachine?.api_partners as any).map((partner) => ({
          label: partner.api_partner_name,
          value: partner.global_api_partner_id,
        }))}
      />
    </>
  ) : (
    <Box>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h2" component="h2">
                    Customer Integrations
                  </Typography>
                </Grid>
                {sessionStorage.getItem('manageIntegrationRecords') === 'true' &&
                  activeMachine.manage_integration_records && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddIntegration}
                        disabled={activeMachine?.online_status === 'offline'}
                      >
                        Add Integration
                      </Button>
                    </Grid>
                  )}
              </Grid>
              <br />
              <DataTable
                noHeader
                columns={integrationColumns}
                data={customerIntegrations}
                defaultSortField="CustomerID"
                defaultSortAsc={false}
                striped
                onRowClicked={(row) => handleEditIntegration(row)}
                highlightOnHover
                pointerOnHover
                progressPending={customerIntegrationsLoading || customerIntegrationsSubmitting}
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntegrationsList;
