/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography, Tabs, Tab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ExternalApiPartnersContext } from '../contexts/ExternalApiPartnersContext';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import TabPanel from '../../shared/tabs/TabPanel';
import URLTabs from '../../shared/tabs/URLTabs';
import PartnerDetails from '../components/PartnerDetails';

const ExternalApiPartnerDetailPage: React.FC = () => {
  const { external_api_partner_id } = useParams<{ external_api_partner_id: string }>();
  const { fetchPartner, partner, partnerLoading } = React.useContext(ExternalApiPartnersContext);

  const tabNames = ['profile'];
  const baseUrl = `/admin/api_management/${partner?.external_api_partner_id}`;
  const { tabValue, handleTabChange } = URLTabs(tabNames, baseUrl);

  React.useEffect(() => {
    if (fetchPartner) fetchPartner(external_api_partner_id);
  }, [external_api_partner_id]);

  const links = [
    { href: '/admin/api_management', text: 'External API Partners' },
    {
      href: `/admin/api_management/${partner?.external_api_partner_id}`,
      text: partner?.name,
    },
  ];

  return partnerLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <CircularProgress />
      <Typography variant="h2" sx={{ fontSize: '18px', mt: 2 }}>
        Loading Partner...
      </Typography>
    </Box>
  ) : (
    <div>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab style={{ textTransform: 'capitalize' }} label="Partner Details" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <PartnerDetails partner={partner} />
      </TabPanel>
    </div>
  );
};

export default ExternalApiPartnerDetailPage;
