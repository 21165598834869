import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Product Name',
    selector: 'product_name',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Description',
    selector: 'product_description',
    sortable: false,
    grow: 1.5,
  },
  {
    name: 'Sources',
    selector: 'source_names',
    sortable: false,
    grow: 0.7,
  },
  {
    name: 'Type',
    selector: 'product_type',
    sortable: false,
    grow: 0.5,
  },
  {
    name: 'Inventory',
    selector: 'product_inventory',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div>
        <p>
          {row.product_inventory && <>{row.product_inventory} </>}
          {row.product_inventory_unit && <> {row.product_inventory_unit} </>}
          <br />
        </p>
      </div>
    ),
  },
  {
    name: 'Density',
    selector: 'product_density',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.product_density) || 0;
      const valB = parseFloat(b.product_density) || 0;
      return valA - valB;
    },
    cell: (row): React.ReactNode => (
      <div>
        <p>
          {row.product_density && <>{row.product_density} </>}
          {row.product_density_type && <> {row.product_density_type} </>}
          <br />
        </p>
      </div>
    ),
  },
  {
    name: `Last Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time',
    sortable: true,
    grow: 1.5,
    sortFunction: (rowA, rowB) =>
      rowA?.date_and_time ? new Date(rowA?.date_and_time).getTime() - new Date(rowB?.date_and_time).getTime() : null,
    cell: (row: any) => (
      <p>{row?.date_and_time ? format(new Date(`${row?.date_and_time} UTC`), 'MM/dd/yyyy hh:mm aaa') : ''}</p>
    ),
  },
  {
    name: 'Last Modified By',
    selector: 'user_name',
    sortable: true,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    cell: (row: any) => <p>{row.active === 'True' ? 'Yes' : 'No'}</p>,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
  },
];

export const integrationColumns: IDataTableColumn<any>[] = [
  {
    name: 'API Partner',
    selector: 'api_partner_name',
    sortable: true,
  },
  {
    name: 'Partner Record ID 1',
    selector: 'partner_record_id_1',
    sortable: true,
  },
  {
    name: 'Partner Record ID 2',
    selector: 'partner_record_id_2',
    sortable: true,
  },
  {
    name: 'Partner Record ID 3',
    selector: 'partner_record_id_3',
    sortable: true,
  },
];
