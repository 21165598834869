import React, { useEffect } from 'react';
import axios from '../../utils/axios.utils';

interface DatabridgeIntegration {
  [key: string]: any;
}

interface AdaptersData {
  [key: string]: any;
}

interface PartnerData {
  [key: string]: any;
}

interface DatabridgeAdminContextInterface {
  integrations?: DatabridgeIntegration[];
  adaptersData?: AdaptersData | null;
  selectedIntegrationId?: string;
  setSelectedIntegrationId?: React.Dispatch<React.SetStateAction<string>>;
  selectedIntegration?: DatabridgeIntegration | null;
  setSelectedIntegration?: React.Dispatch<React.SetStateAction<DatabridgeIntegration | null>>;
  partnerData?: PartnerData | null;
}

const DatabridgeAdminContext = React.createContext<DatabridgeAdminContextInterface>({});

const DatabridgeAdminContextConsumer = DatabridgeAdminContext.Consumer;

const DatabridgeAdminContextProvider: React.FC = ({ children }) => {
  const headers = {
    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
  };

  const [integrations, setIntegrations] = React.useState<DatabridgeIntegration[]>([]);
  const [adaptersData, setAdaptersData] = React.useState<AdaptersData | null>(null);
  const [selectedIntegrationId, setSelectedIntegrationId] = React.useState<string>('');
  const [selectedIntegration, setSelectedIntegration] = React.useState<DatabridgeIntegration | null>(null);
  const [partnerData, setPartnerData] = React.useState<PartnerData | null>(null);

  // useEffect(() => {
  //   axios
  //     .get<string, any>('api/databridge_integrations', { headers })
  //     .then((response) => {
  //       setIntegrations(response.data.results)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching integrations:', error);
  //     });
  // }, []);
  //
  // useEffect(() => {
  //   axios
  //     .get<string, any>('api/databridge_adapters/', { headers })
  //     .then((response) => {
  //       setAdaptersData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching adapters data:', error);
  //     });
  // }, []);
  //
  // useEffect(() => {
  //   if (selectedIntegrationId) {
  //     axios
  //       .get<string, any>(`api/databridge_integrations/${selectedIntegrationId}`, { headers })
  //       .then((response) => {
  //         setSelectedIntegration(response.data.result);
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching selected integration:', error);
  //       });
  //   } else {
  //     setSelectedIntegration(null);
  //   }
  // }, [selectedIntegrationId]);

  return (
    <DatabridgeAdminContext.Provider
      value={{
        integrations,
        adaptersData,
        selectedIntegrationId,
        setSelectedIntegrationId,
        selectedIntegration,
        setSelectedIntegration,
        partnerData,
      }}
    >
      {children}
    </DatabridgeAdminContext.Provider>
  );
};

export { DatabridgeAdminContextProvider, DatabridgeAdminContextConsumer, DatabridgeAdminContext };
