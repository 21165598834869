import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Box,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import { DatabridgeAdminContext } from '../contexts/DatabridgeAdminContext'; // Adjust this import path as needed

const IntegrationSelector = () => {
  const { integrations, selectedIntegrationId, setSelectedIntegrationId } = React.useContext(DatabridgeAdminContext);
  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardHeader title="Select Integration" />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Integration</InputLabel>
              <Select
                value={selectedIntegrationId}
                label="Select Integration"
                onChange={(e) => setSelectedIntegrationId(e.target.value)}
              >
                <MenuItem value="">Select Integration</MenuItem>
                {integrations.map((integration) => (
                  <MenuItem key={integration.databridge_integration_id} value={integration.databridge_integration_id}>
                    {integration.name} (Adapter: {integration.adapter_name}, Partner: {integration.partner_name})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IntegrationSelector;
