import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

// TODO Table fails to pull the cache. A manually refresh will temporary fix the issue.

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Variety Name',
    selector: 'variety',
    sortable: true,
  },
  {
    name: 'Description',
    selector: 'seed_variety_desc',
    sortable: false,
  },
  {
    name: 'Company',
    selector: 'company_name',
    sortable: false,
  },
  {
    name: 'Brand',
    selector: 'supplier_brand',
    sortable: false,
  },
  {
    name: 'Crop',
    selector: 'crop_name',
    sortable: true,
  },
  {
    name: `Last Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time',
    sortable: true,
    grow: 1.5,
    sortFunction: (rowA, rowB) => new Date(rowA.date_and_time).getTime() - new Date(rowB.date_and_time).getTime(),
    cell: (row: any) => (
      <p>{row.date_and_time ? format(new Date(`${row.date_and_time} UTC`), 'MM/dd/yyyy hh:mm aaa') : ''}</p>
    ),
  },
  {
    name: 'Last Modified By',
    selector: 'username',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
    grow: 0.7,
  },
];

export const integrationColumns: IDataTableColumn<any>[] = [
  {
    name: 'API Partner',
    selector: 'api_partner_name',
    sortable: true,
  },
  {
    name: 'Partner Record ID 1',
    selector: 'partner_record_id_1',
    sortable: true,
  },
  {
    name: 'Partner Record ID 2',
    selector: 'partner_record_id_2',
    sortable: true,
  },
  {
    name: 'Partner Record ID 3',
    selector: 'partner_record_id_3',
    sortable: true,
  },
];

export default columns;
