import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<any>[] = [
  {
    name: 'Customer Name',
    selector: 'customer_name',
    sortable: true,
  },
  {
    name: 'First Name',
    selector: 'customer_contact_first',
    sortable: true,
  },
  {
    name: 'Last Name',
    selector: 'customer_contact_last',
    sortable: true,
  },
  {
    name: 'Address',
    selector: 'address',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div>
        <p>
          {row.customer_city && <>{row.customer_city} </>}
          {row.customer_city && row.customer_state && <>, </>}
          {row.customer_state && <> {row.customer_state} </>}
          <br />
        </p>
      </div>
    ),
  },
  {
    name: 'Phone',
    selector: 'customer_phone_1',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'customer_email',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Last Modified',
    selector: 'date_and_time',
    sortable: true,
    grow: 1.5,
    sortFunction: (rowA, rowB) =>
      rowA?.date_and_time ? new Date(rowA?.date_and_time).getTime() - new Date(rowB?.date_and_time).getTime() : null,
    cell: (row: any) => (
      <p>{row?.date_and_time ? format(new Date(`${row?.date_and_time} UTC`), 'MM/dd/yyyy hh:mm aaa') : ''}</p>
    ),
  },
  {
    name: 'Last Modified By',
    selector: 'user_name',
    sortable: true,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    cell: (row: any) => <p>{row.active === 'True' ? 'Yes' : 'No'}</p>,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
  },
];

export const integrationColumns: IDataTableColumn<any>[] = [
  {
    name: 'API Partner',
    selector: 'api_partner_name',
    sortable: true,
  },
  {
    name: 'Partner Record ID 1',
    selector: 'partner_record_id_1',
    sortable: true,
  },
  {
    name: 'Partner Record ID 2',
    selector: 'partner_record_id_2',
    sortable: true,
  },
  {
    name: 'Partner Record ID 3',
    selector: 'partner_record_id_3',
    sortable: true,
  },
];
