/* eslint-disable */

// Place your access token here
// const ACCESS_TOKEN = '...';

import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Stack,
  Snackbar,
  Alert,
  Divider,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

// Import your custom hooks and components
import { DatabridgeAdminContext } from '../contexts/DatabridgeAdminContext'; // Adjust path as needed
import IntegrationSelector from '../components/IntegrationSelector'; // Adjust path as needed
import GlobalConfiguration from '../components/GlobalConfiguration'; // Adjust path as needed
import AdapterPartnerDetails from '../components/AdapterPartnerDetails'; // Adjust path as needed
import MachinesConfiguration from '../components/MachinesConfiguration'; // Adjust path as needed
import PartnerDataDetails from '../components/PartnerDataDetails'; // Adjust path as needed
import AdapterDataDetails from '../components/AdapterDataDetails'; // Adjust path as needed

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#7e57c2',
    },
    secondary: {
      main: '#26c6da',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h4: {
      fontWeight: '600',
    },
    h5: {
      fontWeight: '500',
    },
  },
});

function App() {
  const { selectedIntegrationId, selectedIntegration, setSelectedIntegration } =
    React.useContext(DatabridgeAdminContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [awsSecretId, setAwsSecretId] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [authorizationCode, setAuthorizationCode] = useState('');
  const [configName, setConfigName] = useState('');

  useEffect(() => {
    if (selectedIntegration) {
      setAwsSecretId(selectedIntegration.aws_secret_id || '');
      setRefreshToken(selectedIntegration.refresh_token || '');
      setAuthorizationCode(selectedIntegration.authorization_code || '');
      setConfigName(selectedIntegration.name || '');
    }
  }, []);

  const handleMachineKeyValueChange = (machineId, keyId, newValue) => {
    if (!selectedIntegration) return;
    const updatedIntegration = { ...selectedIntegration };
    const machineIndex = updatedIntegration.machines.findIndex((m) => m.machine_id === machineId);
    if (machineIndex === -1) return;
    const machine = { ...updatedIntegration.machines[machineIndex] };
    const kvIndex = machine.key_values.findIndex((kv) => kv.databridge_integration_machine_key_value_id === keyId);
    if (kvIndex !== -1) {
      const updatedKV = { ...machine.key_values[kvIndex], value: newValue };
      machine.key_values[kvIndex] = updatedKV;
    }
    updatedIntegration.machines[machineIndex] = machine;
    setSelectedIntegration(updatedIntegration);
  };

  const saveMachineConfig = (machineId) => {
    setOpenSnackbar(true);
  };

  const saveIntegrationConfig = () => {
    const dataToSave = {
      databridge_integration_id: selectedIntegrationId,
      name: configName,
      aws_secret_id: awsSecretId,
      refresh_token: refreshToken,
      authorization_code: authorizationCode,
      machines: selectedIntegration
        ? selectedIntegration.machines.map((m) => ({
            machine_id: m.machine_id,
            key_values: m.key_values.map((kv) => ({
              databridge_integration_machine_key_value_id: kv.databridge_integration_machine_key_value_id,
              key: kv.key,
              value: kv.value,
            })),
          }))
        : [],
    };
    console.log('Saving integration config:', JSON.stringify(dataToSave, null, 2));
    setOpenSnackbar(true);
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Box mb={4}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: '600', mb: 2 }}>
          Configure Databridge Integration
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center" sx={{ mb: 4 }}>
          Select an integration, view its details and configure it.
        </Typography>
      </Box>
      <IntegrationSelector />
      {selectedIntegrationId && (
        <>
          <GlobalConfiguration
            configName={configName}
            setConfigName={setConfigName}
            awsSecretId={awsSecretId}
            setAwsSecretId={setAwsSecretId}
            refreshToken={refreshToken}
            setRefreshToken={setRefreshToken}
            authorizationCode={authorizationCode}
            setAuthorizationCode={setAuthorizationCode}
          />
          <AdapterPartnerDetails />
          <MachinesConfiguration
            handleMachineKeyValueChange={handleMachineKeyValueChange}
            saveMachineConfig={saveMachineConfig}
          />
          <PartnerDataDetails />
          <AdapterDataDetails />
          <Box textAlign="center" mt={4}>
            <Button variant="contained" color="primary" onClick={saveIntegrationConfig}>
              Save Integration Config
            </Button>
            <Typography variant="body2" color="textSecondary" mt={2}>
              (This is a sample front-end only. Data will appear in console on save.)
            </Typography>
          </Box>
        </>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Configuration saved (check console)!
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
