import React from 'react';
import { Card, CardHeader, CardContent, Typography, Divider, Box } from '@mui/material';
import { DatabridgeAdminContext } from '../contexts/DatabridgeAdminContext'; // Adjust this import path as needed

const PartnerDataDetails = () => {
  const { partnerData } = React.useContext(DatabridgeAdminContext);
  if (!partnerData) return null;
  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardHeader title="External API Partner Data" />
      <Divider />
      <CardContent>
        <Typography>Name: {partnerData.name}</Typography>
        <Typography>Description: {partnerData.description || 'N/A'}</Typography>
        <Typography>Enabled: {partnerData.enabled ? 'Yes' : 'No'}</Typography>
        <Typography>Last Modified By: {partnerData.last_modified_by}</Typography>
        <Typography>Updated At: {partnerData.updated_at}</Typography>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Machines:
          </Typography>
          {partnerData.machines && partnerData.machines.length > 0 ? (
            partnerData.machines.map((pm) => (
              <Typography key={pm.machine_id}>
                {pm.machine_id} - {pm.name} (SN: {pm.serial_number})
              </Typography>
            ))
          ) : (
            <Typography>No machines for this partner.</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PartnerDataDetails;
