import React from 'react';
import { Card, CardHeader, CardContent, Typography, Divider, Box } from '@mui/material';
import { DatabridgeAdminContext } from '../contexts/DatabridgeAdminContext'; // Adjust this import path as needed

const AdapterDataDetails = () => {
  const { adaptersData } = React.useContext(DatabridgeAdminContext);
  if (!adaptersData) return null;

  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardHeader title="Databridge Adapters Data" />
      <Divider />
      <CardContent>
        <Typography>Name: {adaptersData.name}</Typography>
        <Typography>Description: {adaptersData.description || 'N/A'}</Typography>
        <Typography>Enabled: {adaptersData.enabled ? 'Yes' : 'No'}</Typography>
        <Typography>Last Modified By: {adaptersData.last_modified_by}</Typography>
        <Typography>Updated At: {adaptersData.updated_at}</Typography>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>
            Machines:
          </Typography>
          {adaptersData.machines && adaptersData.machines.length > 0 ? (
            adaptersData.machines.map((am) => (
              <Typography key={am.machine_id}>
                {am.machine_id} - {am.name} (SN: {am.serial_number})
              </Typography>
            ))
          ) : (
            <Typography>No machines listed for this adapter data.</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AdapterDataDetails;
