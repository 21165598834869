import React from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { ExternalApiPartnersContext, ExternalApiPartner } from '../contexts/ExternalApiPartnersContext';
import UserMachineAccessForm from '../../shared/form/UserMachineAccessForm';

interface PartnerFormProps {
  partner?: ExternalApiPartner;
}

const PartnerForm: React.FC<PartnerFormProps> = ({ partner }) => {
  const { updatePartner, createPartner, partnerSubmitting } = React.useContext(ExternalApiPartnersContext);
  const [formValues, setFormValues] = React.useState({
    name: partner?.name || '',
    description: partner?.description || '',
    enabled: partner?.enabled || false,
    company_id: '',
  });

  const [machines, setMachines] = React.useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [generatedCredentials, setGeneratedCredentials] = React.useState<any>({});

  React.useEffect(() => {
    if (partner) {
      setFormValues({
        name: partner.name,
        description: partner.description,
        enabled: partner.enabled,
        company_id: partner.company_id,
      });
    }
  }, [partner]);

  const handleSave = async () => {
    if (partner?.external_api_partner_id) {
      await updatePartner?.(partner.external_api_partner_id.toString(), formValues, () => null);
    } else {
      const response = await createPartner?.(formValues, setGeneratedCredentials);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setGeneratedCredentials(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label="Name"
            value={formValues.name}
            onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label="Description"
            value={formValues.description}
            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label="Company ID"
            value={formValues.company_id}
            onChange={(e) => setFormValues({ ...formValues, company_id: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.enabled}
                onChange={(e) => setFormValues({ ...formValues, enabled: e.target.checked })}
              />
            }
            label="Enabled"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={partnerSubmitting}>
            {partnerSubmitting ? 'Saving...' : partner?.external_api_partner_id ? 'Update Partner' : 'Create Partner'}
          </Button>
        </Grid>
      </Grid>

      {/* Dialog to display generated credentials */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {generatedCredentials?.global_api_partner_id ? 'Partner Created Successfully' : 'Partner Submitting...'}
        </DialogTitle>
        {generatedCredentials?.global_api_partner_id ? (
          <DialogContent>
            <DialogContentText>
              The External API Partner has been created with the following credentials:
            </DialogContentText>
            {generatedCredentials && (
              <>
                <Typography variant="body1">
                  <strong>User ID:</strong> {generatedCredentials.user_id}
                </Typography>
                <Typography variant="body1">
                  <strong>External API Partner ID:</strong> {generatedCredentials.external_api_partner_id}
                </Typography>
                <Typography variant="body1">
                  <strong>Name:</strong> {generatedCredentials.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Email Address:</strong> {generatedCredentials.email_address}
                </Typography>
                <Typography variant="body1">
                  <strong>Global API Partner ID:</strong> {generatedCredentials.global_api_partner_id}
                </Typography>
                <Typography variant="body1">
                  <strong>Access Key:</strong> {generatedCredentials.access_key}
                </Typography>
                <Typography variant="body1">
                  <strong>Access Secret:</strong> {generatedCredentials.access_secret}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Please make sure to store these credentials securely. They will not be shown again.
                </Typography>
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent></DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PartnerForm;
