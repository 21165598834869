/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import DataTable from '../../dataTable/DataTable/DataTable';
import MachineRequestRecordsFilterBar from '../components/MachineRequestRecordsFilterBar';
import { MachineRequestsContext } from '../contexts/MachineRequestsContext';
import machineRequestRecordColumns from '../constants/machineRequestRecordColumns';
import Breadcrumbs from '../../navigation/components/Breadcrumbs';
import MachineRequestDetails from '../components/MachineRequestDetails';
import { AuthContext } from '../../auth/contexts/AuthContext';

const MachineRequestRecordsPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { machineRequestId } = useParams<Record<string, string>>();
  const {
    fetchMachineRequest,
    machineRequest,
    fetchMachineRequestRecords,
    machineRequestRecords,
    totalMachineRequestRecordsCount,
    hasPendingRecords,
    errorMessage,
    machineRequestRecordsLoading,
  } = React.useContext(MachineRequestsContext);

  const { currentUserHasAdminPermission } = React.useContext(AuthContext);

  const [tableFilters, setTableFilters] = React.useState({
    record_info: '',
    response_status: 'All',
    response_message: '',
  });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState<number>(10);
  const [lastUpdatedTimer, setLastUpdatedTimer] = React.useState<number>(0);

  React.useEffect(() => {
    // Check if `machineRequest` was started within the last 5 days
    const isRecentRequest = () => {
      if (!machineRequest?.date_time_start) return false;

      const requestStartDate = new Date(machineRequest.date_time_start);
      const currentDate = new Date();
      const differenceInDays = (currentDate.getTime() - requestStartDate.getTime()) / (1000 * 60 * 60 * 24);

      return differenceInDays <= 5;
    };

    // Only set up the interval if `hasPendingRecords` is true and the request is recent
    if (hasPendingRecords && isRecentRequest()) {
      const intervalId = setInterval(() => {
        console.log(`machineRequest?.record_count: ${machineRequest?.record_count}`);

        const nonPendingRecords = machineRequestRecords?.filter(
          (request_record) => request_record.response_status !== 'Pending',
        ).length;

        if (page === 1 && hasPendingRecords && lastUpdatedTimer > 3) {
          console.log('inside the if');
          fetchMachineRequest(machineRequestId);
          fetchMachineRequestRecords(machineRequestId, tableFilters, '1', perPage.toString());
          setLastUpdatedTimer(0);
        } else if (nonPendingRecords !== machineRequest?.record_count) {
          setLastUpdatedTimer(lastUpdatedTimer + 1);
        }
      }, 1000);

      // Cleanup function to clear the interval when conditions change
      return () => clearInterval(intervalId);
    }

    // Cleanup if polling conditions aren't met
    return () => {};
  }, [hasPendingRecords, page, lastUpdatedTimer, machineRequestRecords, machineRequest]);

  React.useEffect(() => {
    fetchMachineRequestRecords(machineRequestId, tableFilters, page.toString(), perPage.toString());
    fetchMachineRequest(machineRequestId);
  }, []);

  const handleApplyFilters = () => {
    setPage(1);
    setPerPage(perPage);
    fetchMachineRequestRecords(machineRequestId, tableFilters, page.toString(), perPage.toString());
  };

  const handlePageChange = async (passedPage: number) => {
    setPage(passedPage);
    fetchMachineRequestRecords(machineRequestId, tableFilters, passedPage.toString(), perPage.toString());
  };

  const handlePerPageChange = async (perPage: number) => {
    setPerPage(perPage);
    setPage(1);
    // set page to 1 but don't rely on that variable state to update in time for the fetch
    fetchMachineRequestRecords(machineRequestId, tableFilters, '1', perPage.toString());
  };

  const handleViewMachineRequestRecord = (machine_request_id, machine_request_record_id) => {
    history.push(`/machine_requests/${machine_request_id}/machine_request_records/${machine_request_record_id}`);
  };

  const links = [
    {
      href: '/machine_requests/',
      text: 'Machine Requests',
    },
    {
      href: `/machine_requests/${machineRequest?.machine_request_id}/machine_request_records`,
      text: `${machineRequest?.machine_request_type} - ${machineRequest?.machine_request_category}`,
    },
  ];

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Breadcrumbs links={links} />
        </Grid>
      </Grid>
      <br />
      <MachineRequestDetails />
      <br />
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h2" component="h2">
            Machine Request Records
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid justifyContent="space-between" container>
        <Grid item md={12}>
          <MachineRequestRecordsFilterBar
            setFilterValues={setTableFilters}
            filterValues={tableFilters}
            applyFilterValues={handleApplyFilters}
          />
          <br />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </Grid>
      </Grid>
      <br />

      <Card>
        <CardContent>
          <DataTable
            columns={machineRequestRecordColumns}
            data={machineRequestRecords}
            progressPending={machineRequestRecordsLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            pagination
            paginationServer
            paginationTotalRows={totalMachineRequestRecordsCount}
            paginationDefaultPage={page}
            onChangePage={(newPage) => handlePageChange(newPage)}
            onChangeRowsPerPage={(per_page) => handlePerPageChange(per_page)}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 5000]}
            onRowClicked={(row) => {
              if (currentUserHasAdminPermission('ksi_superadmin')) {
                handleViewMachineRequestRecord(machineRequestId, row.machine_request_record_id);
              }
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default MachineRequestRecordsPage;
