import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';

export interface ExternalApiPartner {
  external_api_partner_id: number;
  name: string;
  description: string;
  email?: string;
  company_id?: string;
  enabled: boolean;
  last_modified_by: string;
  updated_at: string;
}

export interface ExternalApiPartnerFormValues {
  name: string;
  description?: string;
  enabled?: boolean;
  machines?: { machine_id: number }[];
}

interface ExternalApiPartnersContextInterface {
  fetchPartners?: () => Promise<void>;
  partners?: ExternalApiPartner[];
  partnersLoading?: boolean;

  fetchPartner?: (partnerId: string) => Promise<void>;
  partner?: ExternalApiPartner;
  partnerLoading?: boolean;

  createPartner?: (
    formValues: ExternalApiPartnerFormValues,
    setCredentials: Dispatch<SetStateAction<any>>,
  ) => Promise<void>;
  updatePartner?: (partnerId: string, formValues: ExternalApiPartnerFormValues, onSuccess: () => void) => Promise<void>;
  partnerSubmitting?: boolean;
}

export const ExternalApiPartnersContext = React.createContext<ExternalApiPartnersContextInterface>({});

export const ExternalApiPartnersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [partners, setPartners] = React.useState<ExternalApiPartner[]>([]);
  const [partnersLoading, setPartnersLoading] = React.useState(true);
  const [partner, setPartner] = React.useState<ExternalApiPartner | undefined>(undefined);
  const [partnerLoading, setPartnerLoading] = React.useState(true);
  const [partnerSubmitting, setPartnerSubmitting] = React.useState(false);

  const fetchPartners = async () => {
    setPartnersLoading(true);
    axios
      .get<any, any>('/api/external_api_partner/', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setPartners(response.data);
        setPartnersLoading(false);
      })
      .catch(() => {
        setPartners([]);
        setPartnersLoading(false);
      });
  };

  const fetchPartner = async (partnerId: string) => {
    setPartnerLoading(true);
    axios
      .get<any, any>(`/api/external_api_partner/${partnerId}/`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setPartner(response.data);
        setPartnerLoading(false);
      })
      .catch(() => {
        setPartner(undefined);
        setPartnerLoading(false);
      });
  };

  const createPartner = async (formValues: ExternalApiPartnerFormValues, setCredentials) => {
    setPartnerSubmitting(true);
    axios
      .post<any, any>('/api/external_api_partner/', formValues, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setPartnerSubmitting(false);
        setCredentials(response.data);
      })
      .catch(() => {
        setPartnerSubmitting(false);
      });
  };

  const updatePartner = async (partnerId: string, formValues: ExternalApiPartnerFormValues, onSuccess: () => void) => {
    setPartnerSubmitting(true);
    axios
      .put<any, any>(`/api/external_api_partner/${partnerId}/`, formValues, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then(() => {
        setPartnerSubmitting(false);
        onSuccess();
        history.push(`/admin/api_manamagenet/${partnerId}`);
      })
      .catch(() => {
        setPartnerSubmitting(false);
      });
  };

  return (
    <ExternalApiPartnersContext.Provider
      value={{
        fetchPartners,
        partners,
        partnersLoading,
        fetchPartner,
        partner,
        partnerLoading,
        createPartner,
        updatePartner,
        partnerSubmitting,
      }}
    >
      {children}
    </ExternalApiPartnersContext.Provider>
  );
};
