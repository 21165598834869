import React from 'react';
import { Grid, TextField, Button } from '@mui/material';

interface FilterValues {
  search?: string;
}

interface PartnerFilterBarProps {
  filterValues: FilterValues;
  setFilterValues: any;
  onSearch?: () => void;
}

const PartnerFilterBar: React.FC<PartnerFilterBarProps> = ({ filterValues, setFilterValues, onSearch }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    if (onSearch) onSearch();
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <TextField
          label="Search"
          variant="outlined"
          name="search"
          value={filterValues.search || ''}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default PartnerFilterBar;
