import { format } from 'date-fns';
import { IDataTableColumn } from '../../../modules/dataTable/DataTable/model';

export const columns: IDataTableColumn<Record<string, string>>[] = [
  {
    name: 'Liquid',
    selector: 'liquid_name',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Description',
    selector: 'liquid_name_desc',
    sortable: false,
    grow: 1.5,
  },
  {
    name: 'Inventory',
    selector: 'liquid_available',
    sortable: true,
    grow: 0.7,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.liquid_available) || 0;
      const valB = parseFloat(b.liquid_available) || 0;
      return valA - valB;
    },
    cell: (row: Record<string, string>): React.ReactNode => {
      const unit = row.metric ? 'mg' : 'fl. oz';
      return <p>{`${row.liquid_available} ${unit}`}</p>;
    },
  },
  {
    name: 'Density',
    selector: 'liquid_density',
    sortable: true,
    sortFunction: (a: Record<string, string>, b: Record<string, string>): unknown => {
      const valA = parseFloat(a.liquid_density) || 0;
      const valB = parseFloat(b.liquid_density) || 0;
      return valA - valB;
    },
    cell: (row: Record<string, string>): React.ReactNode => {
      const unit = row.metric ? 'g/ml' : 'lb/gal';
      return <p>{`${row.liquid_density} ${unit}`}</p>;
    },
  },
  {
    name: 'Liquid Type',
    selector: 'liquid_type',
    sortable: true,
  },
  {
    name: `Last Modified (${new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]})`,
    selector: 'date_and_time',
    sortable: true,
    grow: 1.5,
    sortFunction: (rowA, rowB) => new Date(rowA.date_and_time).getTime() - new Date(rowB.date_and_time).getTime(),
    cell: (row: Record<string, string>): React.ReactNode => (
      <p>{row.date_and_time ? format(new Date(`${row.date_and_time} UTC`), 'MM/dd/yyyy hh:mm aaa') : ''}</p>
    ),
  },
  {
    name: 'Last Modified By',
    selector: 'username',
    sortable: true,
    grow: 1.3,
  },
  {
    name: 'Active',
    selector: 'active',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'Mfr',
    selector: 'manufacturer_list',
    sortable: true,
    grow: 0.5,
  },
  {
    name: 'API Linked',
    selector: 'api_linked',
    sortable: true,
    grow: 0.7,
  },
];

export const integrationColumns: IDataTableColumn<any>[] = [
  {
    name: 'API Partner',
    selector: 'api_partner_name',
    sortable: true,
  },
  {
    name: 'Partner Record ID 1',
    selector: 'partner_record_id_1',
    sortable: true,
  },
  {
    name: 'Partner Record ID 2',
    selector: 'partner_record_id_2',
    sortable: true,
  },
  {
    name: 'Partner Record ID 3',
    selector: 'partner_record_id_3',
    sortable: true,
  },
];
